<template>
  <content-form :edit="true" :content="content" v-if="content != null" />
</template>

<script>
import { mapGetters } from "vuex";
import ContentForm from "./ContentForm.vue";

export default {
  name: "ContentUpdate",

  components: {
    ContentForm,
  },

  computed: {
    ...mapGetters("contents", ["getSiloContentById"]),
  },

  data() {
    return {
      content: null,
    };
  },

  created() {
    if (this.$route.params.id == undefined) this.$router.push("/contents");

    this.content = this.getSiloContentById(this.$route.params.id);

    if (!this.content) this.$router.push("/contents");
  },
};
</script>